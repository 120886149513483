<template>
  <div class="escalator-monitor">
    <div class="em-wrapper">
      <div class="em-title">{{ elevator.name }}</div>
      <div class="em-monitor" :class="{
        'em-monitor-slow': monitorSpeed === '慢',
        'em-monitor-down': monitorData.Operation_Direction === 2,
        'em-monitor-run': monitorRun,
      }"></div>
      <div v-if="wsShowTip" class="em-connect-status-wrapper">
        <div class="em-connect-status">
          <div class="tip">
            <img class="icon" src="/static/images/escalatorMonitor/loading.gif"/>
            <div class="text">
              <div style="text-align: left">
                {{ wsTipText }}
              </div>
              <div style="text-align: left">
                {{ wsTipFaultText }}
              </div>
              <div v-if="
                curFaultDesc.length > 0 && wsTipText.includes('发生故障-代码')
              " style="font-size: 20px; cursor: pointer" @click="
                getSolution(elevator.id, curFaultCode, curFaultDesc, 10)
                ">
                <img height="15px" src="/static/images/elevator/tingti.png"/>&nbsp;
                {{ curFaultDesc }}
              </div>
            </div>
          </div>
          <el-button v-if="wsShowRetry" class="retry" type="primary" icon="el-icon-refresh" @click="createWs">
            {{ $t("monitor.retry") }}
          </el-button>
        </div>
      </div>
      <div class="em-detail">
        <div class="em-status">
          <img :src="monitorStatusIcon"/><br/>
          <div style="display: flex; margin-top: 20px">
            <div class="speedTitle">{{ $t("monitor.runningSpeed") }}</div>
            <div class="speedValue">{{ monitorSpeed }}</div>
          </div>
        </div>
        <div v-if="sigma.elevatorId > 0" class="em-count">
          <div style="display: flex; margin-top: 20px">
            <div class="countA">上 {{ sigma.countA }}</div>
            <div class="countB">下 {{ sigma.countB }}</div>
          </div>
        </div>
        <div v-if="testAuth" class="em-control">
          <div>
            <el-button size="medium" type="primary" @click="doControl(1)">{{
              $t("monitor.lock")
            }}</el-button>
          </div>
          <div>
            <el-button size="medium" type="primary" @click="doControl(2)">{{
              $t("monitor.goUp")
            }}</el-button>
          </div>
          <div>
            <el-button size="medium" type="primary" @click="doControl(3)">{{
              $t("monitor.goDown")
            }}</el-button>
          </div>
          <div>
            <el-button size="medium" type="primary" @click="getRecord()">{{
              $t("monitor.record")
            }}</el-button>
          </div>
          <escalator-monitor-verify-code ref="escalatorMonitorVerifyCode"
            @select="getCode"></escalator-monitor-verify-code>
          <escalator-monitor-record ref="escalatorMonitorRecord"></escalator-monitor-record>
        </div>
        <el-tabs v-model="activeName" class="tab" type="border-card">
          <el-tab-pane :label="$t('monitor.information')" name="first">
            <div class="info-item">{{ elevator.name }}</div>
            <div class="info-item">
              <span class="title">{{ $t("monitor.check") }}:</span>
              <span class="value">{{ otherInfo.nextYearCheckDate }}</span>
            </div>
            <div class="info-item">
              <span class="title">{{ $t("monitor.maintenance") }}:</span>
              <span class="value">{{ otherInfo.nextMaintDate }}</span>
            </div>
            <div class="info-item">
              <span class="title">{{ $t("monitor.workingTime") }}:</span>
              <span class="value">{{ monitorData.totalRunningTime }}
                {{ $t("device.minute") }}</span>
            </div>
            <div class="info-item">
              <span class="title">{{ $t("monitor.faultTime") }}:</span>
              <span class="value">{{ monitorData.faultTime }}</span>
            </div>
            <el-tabs value="first" style="margin-top: 10px" @tab-click="changeTabs">
              <el-tab-pane :label="$t('monitor.elevator')" name="first">
                <div class="info-item">
                  <span class="title">{{ $t("elevator.no") }}：</span>
                  <span class="value">{{ elevator.no }}</span>
                </div>
                <div class="info-item">
                  <span class="title">{{ $t("elevator.name") }}：</span>
                  <span class="value">{{ elevator.name }}</span>
                </div>
                <div class="info-item">
                  <span class="title">{{ $t("elevator.elevator")
                  }}{{ $t("elevator.dtuCode") }}：</span>
                  <span class="value">{{ elevator.dtuCode }}</span>
                </div>
                <div class="info-item">
                  <span class="title">{{ $t("elevator.elevator")
                  }}{{ $t("elevator.nodeCode") }}：</span>
                  <span class="value">{{ elevator.nodeCode }}</span>
                </div>
                <div class="info-item">
                  <span class="title">{{ $t("elevator.elevator")
                  }}{{ $t("elevator.model") }}：</span>
                  <span class="value">{{ elevator.model }}</span>
                </div>
                <div class="info-item">
                  <span class="title">{{ $t("elevator.elevatorType") }}：</span>
                  <span class="value">{{ elevator.elevatorProductName }}</span>
                </div>
                <div class="info-item">
                  <span class="title">{{ $t("elevator.elevator")
                  }}{{ $t("developer.address") }}：</span>
                  <span class="value">{{ elevator.districtFullName }}</span>
                </div>
                <div class="info-item">
                  <span class="title">{{ $t("elevator.useUnit") }}：</span>
                  <span class="value">{{ elevator.useUnitName }}</span>
                </div>
              </el-tab-pane>
              <el-tab-pane :label="$t('elevator.emergencyRecord')" name="second">
                <el-table :data="otherInfo.workOrderRecords" height="296px">
                  <table-column prop="alarmTime" :label="$t('workOrder.alarmTime')"></table-column>
                  <table-column prop="workOrderNo" :label="$t('workOrder.workOrderNo')">
                    <template #default="scope">
                      <router-link :to="'/workOrder/' + scope.row.id" target="_blank">
                        {{ scope.row.workOrderNo }}
                      </router-link>
                    </template>
                  </table-column>
                </el-table>
              </el-tab-pane>
              <el-tab-pane :label="$t('elevator.maintenanceRecord')" name="third">
                <el-table v-if="siteName === 'G-Cloud电梯云'" :data="otherInfo.maintRecords" height="296px">
                  <table-column prop="maintDate" :label="$l('maintWorkOrder.maintDate', '保养日期')"></table-column>
                  <table-column prop="maintStatus" :label="$l('maintWorkOrder.maintStatus', '状态')">
                    <template #default="scope">
                      <el-tag v-if="scope.row.maintStatus === 0" type="danger">
                        {{ $l("maintWorkOrder.invalid", "作废") }}
                      </el-tag>
                      <el-tag v-else-if="scope.row.maintStatus === 1" type="warning">
                        {{ $l("maintWorkOrder.inPlan", "计划中") }}
                      </el-tag>
                      <el-tag v-else-if="scope.row.maintStatus === 2">
                        {{ $l("maintWorkOrder.notAccepted", "未接受") }}
                      </el-tag>
                      <el-tag v-else-if="scope.row.maintStatus === 3">
                        {{ $l("maintWorkOrder.accepted", "已接受") }}
                      </el-tag>
                      <el-tag v-else-if="scope.row.maintStatus === 4" type="info">
                        {{ $l("maintWorkOrder.signedIn", "已签到") }}
                      </el-tag>
                      <el-tag v-else-if="scope.row.maintStatus === 5" type="success">
                        {{ $l("maintWorkOrder.completed", "已完成") }}
                      </el-tag>
                    </template>
                  </table-column>
                </el-table>
                <el-table v-else :data="otherInfo.oldMaintRecords" height="296px">
                  <table-column prop="maintDate" :label="$t('maintWorkOrder.date')"></table-column>
                  <table-column prop="status" :label="$t('maintWorkOrder.status')">
                    <template #default="scope">
                      <el-tag v-if="scope.row.status === 1">{{
                        $t("maintWorkOrder.works")
                      }}</el-tag>
                      <el-tag v-if="scope.row.status === 2" type="danger">
                        {{ $t("maintWorkOrder.execution") }}
                      </el-tag>
                      <el-tag v-if="scope.row.status === 3" type="warning">
                        {{ $t("maintWorkOrder.stocks") }}
                      </el-tag>
                      <el-tag v-if="scope.row.status === 4" type="success">
                        {{ $t("maintWorkOrder.confirmed") }}
                      </el-tag>
                    </template>
                  </table-column>
                </el-table>
              </el-tab-pane>
              <el-tab-pane :label="$t('workOrder.faultRecords')" name="fourth">
                <el-table :data="otherInfo.faultRecords" height="296px" width="100%">
                  <table-column prop="faultTime" :label="$t('workOrder.faultTime')"></table-column>
                  <table-column prop="faultType" :label="$t('faultTemplate.type')" width="50px"></table-column>
                  <table-column prop="faultCode" :label="$t('faultTemplate.faultCode')" width="100px"></table-column>
                  <table-column prop="faultDesc" :label="$t('potentialFault.faultDesc')">
                    <template #default="scope">
                      <span v-if="scope.row.faultDesc === '000000'">{{
                        $t("workOrder.unKnowFault")
                      }}</span>
                      <span v-else>{{ scope.row.faultDesc }}</span>
                    </template>
                  </table-column>
                  <table-column prop="faultDesc" width="50px">
                    <template #default="scope">
                      <el-button type="warning" icon="el-icon-setting" circle @click="
                        getSolution(
                          elevator.id,
                          scope.row.faultCode,
                          scope.row.faultDesc,
                          10
                        )
                        "></el-button>
                    </template>
                  </table-column>
                </el-table>
              </el-tab-pane>
            </el-tabs>
          </el-tab-pane>
          <!-- <el-tab-pane :label="$t('monitor.liveData')" name="second">
            <div class="vm-separate">
              <div>
                <div class="info-item">
                  <span class="title">{{ $t("monitor.setFrequency") }}:</span>
                  <span class="value"
                    >{{ (liveData.d0E * 1.0) / 100 }}&nbsp;HZ</span
                  >
                </div>
                <div class="info-item">
                  <span class="title"
                    >{{ $t("monitor.OutputFrequency") }}:</span
                  >
                  <span class="value"
                    >{{ (liveData.d10 * 1.0) / 100 }}&nbsp;HZ</span
                  >
                </div>
                <div class="info-item">
                  <span class="title">{{ $t("monitor.OutputVoltage") }}:</span>
                  <span class="value">{{ liveData.d14 }}&nbsp;V</span>
                </div>
                <div class="info-item">
                  <span class="title">{{ $t("monitor.OutputCurrent") }}:</span>
                  <span class="value">{{ liveData.d15 }}&nbsp;A</span>
                </div>
                <div class="info-item">
                  <span class="title">{{ $t("monitor.DCBusVoltage") }}:</span>
                  <span class="value">{{ liveData.d19 }}&nbsp;V</span>
                </div>
                <div class="info-item">
                  <span class="title"
                    >{{ $t("monitor.PowerOnTimeAccumulation") }}:</span
                  >
                  <span class="value">{{ liveData.d37 }}&nbsp;H</span>
                </div>
                <div class="info-item">
                  <span class="title">{{ $t("monitor.RunningTime") }}:</span>
                  <span class="value">{{ liveData.d38 }}&nbsp;H</span>
                </div>
                <div class="info-item">
                  <span class="title">{{ $t("monitor.FaultCode") }}:</span>
                  <span class="value">
                    {{ liveData.d3D }}
                    <el-button
                      v-if="curFaultDesc1"
                      type="text"
                      @click="
                        getSolution(
                          elevator.id,
                          liveData.d3D,
                          curFaultDesc1,
                          20
                        )
                      "
                      >{{ "【" + curFaultDesc1 + "】" }}</el-button>
                  </span>
                </div>
              </div>
            </div>
          </el-tab-pane> -->
          <el-tab-pane :label="$t('home.elevatorStatus')" name="tabFault">
            <table class="vm-table">
              <tr>
                <td :style="{ color: hasFault(0) ? '#ff0000' : '#666' }">
                  {{ $t("elevator.escalatorUp") }}
                </td>
                <td :style="{ color: hasFault(1) ? '#ff0000' : '#666' }">
                  {{ $t("elevator.escalatorDown") }}
                </td>
                <td :style="{ color: hasFault(2) ? '#ff0000' : '#666' }">
                  {{ $t("elevator.escalatorRun") }}
                </td>
                <td :style="{ color: hasFault(3) ? '#ff0000' : '#666' }">
                  {{ $t("elevator.fault") }}
                </td>
              </tr>
              <tr>
                <td :style="{ color: hasFault(4) ? '#ff0000' : '#666' }">
                  {{ $t("deviceGateway.overhaul") }}
                </td>
                <td :style="{ color: hasFault(5) ? '#ff0000' : '#666' }">
                  {{ $t("elevator.escalatorStop") }}
                </td>
                <td :style="{ color: hasFault(6) ? '#ff0000' : '#666' }">
                  {{ $t("elevator.energryRun") }}
                </td>
                <td :style="{ color: hasFault(7) ? '#ff0000' : '#666' }">
                  {{ $t("elevator.fire") }}
                </td>
              </tr>
              <tr>
                <td :style="{ color: hasFault(8) ? '#ff0000' : '#666' }">
                  {{ $t("elevator.breakSystem") }}
                </td>
                <td :style="{ color: hasFault(3) ? '#ff0000' : '#666' }">
                  {{ $t("home.enterStopMode") }}
                </td>
                <td></td>
                <td></td>
              </tr>
            </table>
            <el-divider content-position="left">{{
              $t("elevator.fault")
            }}</el-divider>
            <table class="vm-table">
              <tr>
                <td>{{ $t("monitor.status.safety") }}</td>
                <td>{{ $t("monitor.status.speeding") }}</td>
              </tr>
              <tr>
                <td>{{ $t("elevator.UnintendedReversal") }}</td>
                <td>{{ $t("elevator.missingStep") }}</td>
              </tr>
              <tr>
                <td>{{ $t("elevator.handSpeed") }}</td>
                <td>{{ $t("elevator.breakFail") }}</td>
              </tr>
              <tr>
                <td>{{ $t("elevator.UnintendedReversal") }}</td>
                <td>{{ $t("elevator.otherFault") }}</td>
              </tr>
            </table>
          </el-tab-pane>
          <el-tab-pane :label="$t('elevator.datSta')" name="fourth">
            <div class="vm-separate">
              <div>
                <div class="info-item">
                  <span class="title">{{ $t("deviceGateway.runTime") }}:</span>
                  <span class="value"></span>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <fault-solution ref="faultSolution"></fault-solution>
  </div>
</template>
<script>
import EscalatorMonitorVerifyCode from "./EscalatorMonitorVerifyCode.vue";
import EscalatorMonitorRecord from "./EscalatorMonitorRecord.vue";
import FaultSolution from "./FaultSolution.vue";

const wsUrl = window.config.wsUrl;
export default {
  components: {
    EscalatorMonitorVerifyCode,
    EscalatorMonitorRecord,
    FaultSolution,
  },
  data() {
    return {
      faultCode: "",
      activeName: "first",
      testAuth: this.$auth(136),
      siteName: window.config.siteName,
      ws: null,
      wsShowTip: false,
      wsShowRetry: false,
      wsTipText: "连接中...",
      wsTipFaultText: "",
      verifyCode: "",
      timer: null,
      controlType: 0,
      elevator: {},
      otherInfo: {
        nextYearCheckDate: "",
        nextMaintDate: "",
        workOrderRecords: [],
        maintRecords: [],
        oldMaintRecords: [],
        faultRecords: [],
      },
      monitorData: {
        fault: 0,
        repair: 0,
        fire: 0,
        leisureStop: 0,
        leisureSlow: 0,
        Service_Mode: 0,
        Operation_Status: 0,
        Operation_Direction: -1,
        totalRunningTime: "",
        faultTime: "",
        braking: 0,
        Fault_Code: "",
      },
      liveData: {
        d00: "",
        d01: "",
        d03: "",
        d05: "",
        d06: "",
        d07: "",
        d0A: "",
        d0E: "",
        d10: "",
        d14: "",
        d15: "",
        d19: "",
        d37: "",
        d38: "",
        d3D: "",
      },
      faultSolution: [],
      faultSolution1: [],
      curFaultDesc: "",
      curFaultDesc1: "",
      curFaultCode: "",
      sigma: {
        elevatorId: null,
        username: null,
        countA: null,
        countB: null,
        lastUpdateTime: null,
      },
    };
  },

  watch: {
    faultCode(val) {
      console.log(val);
      if (val) {
        this.getFaultDesc(val);
      }
    }
  },
  computed: {
    monitorRun() {
      return (
        this.monitorData.Operation_Status === 1 &&
        this.monitorData.Operation_Direction !== 0 &&
        this.monitorData.leisureStop !== 1
      );
    },
    monitorSpeed() {
      if (!this.monitorRun) {
        return this.$t("elevator.stop");
      }
      return this.monitorData.leisureSlow
        ? this.$t("elevator.slow")
        : this.$t("elevator.fast");
    },
    monitorStatusIcon() {
      // 消防 检修 故障
      let path = "/static/images/escalatorMonitor/";
      if (this.monitorData.fault === 1) {
        return path + (this.$i18n.locale === "zh-CN"
          ? "fault.png"
          : this.$i18n.locale === "en-US"
            ? "fault-en.png"
            : "fault-it.png");
      }
      if (this.monitorData.repair === 1) {
        return path + (this.$i18n.locale === "zh-CN"
          ? "repair.png"
          : this.$i18n.locale === "en-US"
            ? "repair-en.png"
            : "repair-it.png");
      }
      if (this.monitorData.fire === 1) {
        return path + (this.$i18n.locale === "zh-CN"
          ? "fire.png"
          : this.$i18n.locale === "en-US"
            ? "fire-en.png"
            : "fire-it.png");
      }
      if (this.monitorData.Operation_Direction === 0) {
        return path + (this.$i18n.locale === "zh-CN"
          ? "stop.png"
          : this.$i18n.locale === "en-US"
            ? "stop-en.png"
            : "stop-it.png");
      }
      if (this.monitorData.Operation_Direction === 1) {
        return path + (this.$i18n.locale === "zh-CN"
          ? "up.png"
          : this.$i18n.locale === "en-US"
            ? "up-en.png"
            : "up-it.png");
      }
      if (this.monitorData.Operation_Direction === 2) {
        return path + (this.$i18n.locale === "zh-CN"
          ? "down.png"
          : this.$i18n.locale === "en-US"
            ? "down-en.png"
            : "down-it.png");
      }
      return "";
    },
  },
  destroyed() {
    if (this.ws) {
      this.ws.close();
      this.verifyCode = "";
      this.controlType = 0;
    }
  },
  methods: {
    init(data) {
      document.title = this.$t("monitor.escalatorMonitor");
      this.elevator = data.elevator;
      this.otherInfo.nextYearCheckDate = data.nextYearCheckDate;
      this.otherInfo.nextMaintDate = data.nextMaintRecordDate;
      this.otherInfo.workOrderRecords = data.workOrderRecordVos;
      this.otherInfo.maintRecords = data.maintRecordVos;
      this.otherInfo.oldMaintRecords = data.oldMaintRecordVos;
      this.otherInfo.faultRecords = data.faultRecordVos;
      this.createWs();
    },
    hasFault(bit) {
      switch (bit) {
        case 0:
          return this.monitorData.Operation_Direction === 1;
        case 1:
          return this.monitorData.Operation_Direction === 2;
        case 2:
          return (
            this.monitorData.Operation_Direction === 1 ||
            this.monitorData.Operation_Direction === 2
          );
        case 3:
          return this.monitorData.fault === 1;
        case 4:
          return this.monitorData.repair === 1;
        case 5:
          return !(
            this.monitorData.Operation_Status === 1 &&
            this.monitorData.Operation_Direction !== 0 &&
            this.monitorData.leisureStop !== 1
          );
        // return this.monitorData.leisureStop === 1;
        case 6:
          return this.monitorData.leisureSlow === 1;
        case 7:
          return this.monitorData.fire === 1;
        case 8:
          return this.monitorData.braking === 1;
      }
    },
    createWs() {
      this.wsShowTip = false;
      this.wsShowRetry = false;
      try {
        this.ws = new WebSocket(wsUrl);
        this.initWsEvent();
      } catch (e) {
        this.wsTipText = this.$t("monitor.error");
        this.showRetry();
      }
    },
    showRetry() {
      this.wsShowTip = true;
      this.wsShowRetry = true;
    },
    initWsEvent() {

      this.ws.onopen = () => {
        this.wsShowTip = true;
        this.wsTipText = this.$t("monitor.escalatorConnect");
        let authProto = {
          t: "B00",
          d: { regCode: this.elevator.regNo },
        };
        this.ws.send(JSON.stringify(authProto)); // 发送认证信息
      };
      this.ws.onclose = () => {
        this.showRetry();
      };
      this.ws.onerror = () => {
        this.wsTipText = this.$t("monitor.error");
        this.showRetry();
      };
      this.ws.onmessage = (event) => {
        try {
          let proto = JSON.parse(event.data);
          let type = proto.t;
          switch (type) {
            case "A05":
              let beforeNum = proto.d.beforeNum;
              this.wsTipText = this.$t("monitor.a05Describe") + beforeNum;
              break;
            case "A06":
              this.wsShowTip = true;
              this.wsTipText = this.$t("monitor.a06Describe");
              break;
            case "A08":
              this.wsShowTip = true;
              this.wsTipText = this.$t("monitor.a08Describe");
              break;
            case "A10":
              this.wsShowTip = true;
              this.wsTipText = this.$t("monitor.a10Describe");
              break;
            case "A11":
              this.wsShowTip = true;
              this.wsTipText = this.$t("monitor.a11Describe");
              break;
            case "A09":
              this.monitorData = proto.d;
              if (this.monitorData.fault === 1) {
                this.wsShowTip = true;
                this.faultCode = this.monitorData.Fault_Code;
                this.wsTipText = this.$t("monitor.faultCode") + ": " + (this.faultCode || this.$t("monitor.status.unKnow"));
              } else if (this.monitorData.repair === 1) {
                this.wsShowTip = true;
                this.wsTipText = this.$t("monitor.status.service");
              } else if (this.monitorData.fire === 1) {
                this.wsShowTip = true;
                this.wsTipText = this.$t("monitor.status.fire");
              } else {
                this.wsShowTip = false;
              }
              break;
            case "B4":
              this.liveData = proto.d;
              // if (this.liveData.d3D && !this.curFaultDesc1) {
              //   if (this.faultSolution1.length === 0) {
              //     this.getFaultSolution1();
              //   } else {
              //     for (let item of this.faultSolution1) {
              //       if (
              //         item.fcode.toString().trim() ===
              //         this.liveData.d3D.toString().trim()
              //       ) {
              //         this.curFaultDesc1 = item.fdesc;
              //         return;
              //       }
              //     }
              //   }
              // }
              break;
          }
        } catch (e) {
          console.log(e);
        }
      };
    },

    // 根据故障模板id获取所有的故障配置并过滤出对应的故障描述
    getFaultDesc(faultCode) {
      this.$http.get(`faults/${this.elevator.faultId}`).then(res => {
        const faultSettings = res.data.faultSettings;
        const result = faultSettings.find(item => item.code == faultCode);
        if (result) {
          this.wsTipFaultText = this.$t("thirdPartyPlatform.note") + ": " + result.desc;
        } else {
          this.wsTipFaultText = this.$t("monitor.status.unKnow");
        }
      }).catch((error) => {
        if (error.response) {
          this.$message.error(this.$t("common.tip.getDataError") + "," + error.response.data.message);
        }
      });
    },

    // getFaultSolution() {
    //   let params = {
    //     faultType: 10,
    //   };
    //   this.$api
    //     .getData("solutionSettings/solution/" + this.elevator.id, params)
    //     .then((res) => {
    //       console.log("解决方案>>>>>>>>>>>>>>>>>>>>>", res);
    //       this.faultSolution = res.data;
    //     });
    // },
    // getFaultSolution1() {
    //   let params = {
    //     faultType: 20,
    //   };
    //   this.$api
    //     .getData("solutionSettings/solution/" + this.elevator.id, params)
    //     .then((res) => {
    //       this.faultSolution1 = res.data;
    //     });
    // },
    getCode(code) {
      this.verifyCode = code;
      this.doControl(this.controlType);
    },
    doControl(type) {
      this.controlType = type;
      if (this.verifyCode === "") {
        this.$refs.escalatorMonitorVerifyCode.open();
      } else {
        let URI = "";
        let typeName = "";
        if (type === 1) {
          // 锁梯
          typeName = this.$t("monitor.lock");
          URI = "remote/escalatorControl/lock";
        } else if (type === 2) {
          // 上行
          typeName = this.$t("monitor.goUp");
          URI = "remote/escalatorControl/up";
        } else if (type === 3) {
          // 下行
          typeName = this.$t("monitor.goDown");
          URI = "remote/escalatorControl/down";
        }
        this.$confirm("确定执行" + typeName + "吗？", this.$t("common.tips"), {
          confirmButtonText: this.$t("common.confirm"),
          cancelButtonText: this.$t("common.cancel"),
          type: "warning",
        }).then(() => {
          let params = {
            code: this.verifyCode,
            elevatorId: this.elevator.id,
          };
          this.$api
            .getData(URI, params)
            .then((res) => {
              this.$message.success("执行" + typeName + "成功");
              this.dialogVisible = false;
            })
            .catch((error) => {
              if (error.response.data.code === 1) {
                this.$message.error("验证码不正确或者已过期");
                this.verifyCode = "";
                this.$refs.escalatorMonitorVerifyCode.open();
              } else {
                this.$message.error(
                  "执行" + typeName + "失败," + error.response.data.message
                );
              }
            });
          this.controlType = 0;
        });
      }
    },
    getRecord() {
      this.$refs.escalatorMonitorRecord.open(this.elevator.id);
    },
    getSolution(elevatorId, faultCode, faultDesc, faultType) {
      this.$refs.faultSolution.open(
        elevatorId,
        faultCode,
        faultDesc,
        faultType
      );
    },
    changeTabs(tab, event) {
      if (tab.name === "second") {
        this.$api
          .getById("singleLadderMonitor", this.$route.params.elevatorId)
          .then((res) => {
            this.otherInfo.workOrderRecords = res.data.workOrderRecordVos;
          })
          .catch((e) => {
            this.$message.error("获取数据失败");
          });
      }
      if (tab.name === "fourth") {
        this.$api
          .getById("singleLadderMonitor", this.$route.params.elevatorId)
          .then((res) => {
            this.otherInfo.faultRecords = res.data.faultRecordVos;
          })
          .catch((e) => {
            this.$message.error("获取数据失败");
          });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.escalator-monitor {
  background-color: black;
  width: 100%;
  height: 100vh;
  min-height: 750px;
  padding-top: 20px;
  box-sizing: border-box;

  .em-wrapper {
    height: 660px;
    width: 1480px;
    margin: 0 auto;
    background: url(/static/images/escalatorMonitor/bg.jpg) no-repeat center;
    background-size: cover;
    position: relative;

    .em-title {
      background-color: #fcfcfc;
      padding: 12px 15px;
    }

    .em-count {
      position: absolute;
      top: 160px;
      right: 550px;
      text-align: center;

      .countA {
        height: 30px;
        line-height: 30px;
        border: 1px solid #ddd;
        background-color: white;
        padding: 0 10px;
        font-size: 17px;
        border-right: none;
        border-radius: 3px 0 0 3px;
      }

      .countB {
        height: 30px;
        line-height: 30px;
        border: 1px solid #368de6;
        background-color: #368de6;
        font-size: 17px;
        padding: 0 10px;
        border-radius: 0 3px 3px 0;
        border-left: none;
        color: white;
      }
    }

    .em-status {
      position: absolute;
      top: 80px;
      right: 750px;
      text-align: center;

      .speedTitle {
        height: 30px;
        line-height: 32px;
        border: 1px solid #ddd;
        background-color: white;
        padding: 0 10px;
        font-size: 13px;
        border-right: none;
        border-radius: 3px 0 0 3px;
      }

      .speedValue {
        height: 30px;
        line-height: 30px;
        border: 1px solid #368de6;
        background-color: #368de6;
        font-size: 17px;
        padding: 0 10px;
        border-radius: 0 3px 3px 0;
        border-left: none;
        color: white;
      }
    }

    .em-control {
      position: absolute;
      top: 300px;
      right: 670px;
      text-align: center;

      div {
        margin-bottom: 3px;
      }
    }

    .em-monitor {
      position: absolute;
      width: 535px;
      left: 80px;
      top: 80px;
      height: 100%;
      background: url(/static/images/escalatorMonitor/animate.png) no-repeat 0 0;
    }

    .em-monitor-run {
      animation: monitor-animate 0.6s step-start infinite;
      -moz-animation: monitor-animate 0.6s step-start infinite;
    }

    .em-monitor-slow {
      animation-duration: 2s;
      -moz-animation-duration: 2s;
    }

    .em-monitor-down {
      animation-direction: reverse;
      -moz-animation-direction: reverse;
    }

    .em-connect-status-wrapper {
      position: absolute;
      background-color: rgba(0, 0, 0, 0.5);
      width: 100%;
      height: 100%;

      .em-connect-status {
        position: absolute;
        top: 40%;
        left: 16%;
        transform: translate(-16%, -40%);
        text-align: center;

        .tip {
          display: flex;
          align-items: center;

          .icon {
            width: 60px;
            background-color: rgba(164, 243, 145, 0.1);
            padding: 5px;
            border: 1px solid white;
            border-radius: 50%;
          }

          .text {
            color: white;
            font-size: 30px;
            margin-left: 10px;
          }
        }

        .retry {
          margin-top: 20px;
        }
      }
    }

    .em-detail {
      position: absolute;
      left: 400px;
      right: 0;
      top: 0;
      bottom: 0;
      background: url(/static/images/escalatorMonitor/detailBg.png) no-repeat right;
      background-size: cover;

      .tab {
        position: absolute;
        top: 30px;
        right: 40px;
        width: 570px;

        table.vm-table {
          width: 100%;
          font-size: 13px;
          table-layout: fixed;
          border-spacing: 0;
          border-collapse: unset;
          border-top: #eee 1px solid;
          border-right: #eee 1px solid;

          th,
          td {
            border-left: 1px solid #eee;
            border-bottom: 1px solid #eee;
          }

          th {
            color: #555;
            padding: 9px 20px;
            background-color: #fff;
            text-align: left;
            font-weight: normal;
            position: relative;
          }

          td {
            color: #666;
            padding: 11px 5px;
            background-color: #ffffff;
            text-align: center;
          }

          td.selected {
            background-color: #e91e63;
            color: white;
          }
        }

        .info-item {
          padding: 10px;
          border-bottom: 1px dotted #ccc;
          font-size: 14px;
          color: #333;

          .title {
            color: #888;
          }
        }
      }
    }

    @keyframes monitor-animate {
      0% {
        background-position: 0 0;
      }

      12.5% {
        background-position: -535px 0;
      }

      25% {
        background-position: -1070px 0;
      }

      37.5% {
        background-position: -1605px 0;
      }

      50% {
        background-position: -2140px 0;
      }

      62.5% {
        background-position: -2675px 0;
      }

      75% {
        background-position: -3210px 0;
      }

      87.5% {
        background-position: -3745px 0;
      }

      100% {
        background-position: 0 0;
      }
    }
  }
}
</style>
