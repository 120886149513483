<template>
  <el-dialog
    :title="title"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    width="460px"
    top="20vh"
    append-to-body
    class="small-padding"
    @close="onDialogClose">
    <div v-loading="contentLoading" class="body">
      <el-form ref="formValidate" class="form" :model="verifyCode" :rules="ruleValidate">
        <el-form-item prop="phone">
          <el-input v-model="verifyCode.phone" :placeholder="$t('common.pleaseEnter')" disabled>
            <template slot="prepend"> {{ $t("user.phone") }}</template>
          </el-input>
        </el-form-item>
        <el-form-item prop="code">
          <el-input v-model="verifyCode.code" :placeholder="$t('common.pleaseEnter')" style="width: 280px">
            <template slot="prepend">{{ $t("login.verify") }}</template>
          </el-input>
          <el-button
            type="success"

            :disabled="sendMsgBtnDisabled"
            :loading="sendMsgBtnLoading"
            @click="sendMsg">
            {{sendMsgBtnText}}
          </el-button>
        </el-form-item>
        <el-button type="primary" style="width: 100%" @click="handleSubmit()">{{ $t("login.submit") }}</el-button>
      </el-form>
    </div>
  </el-dialog>
</template>

<script>
  export default {
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        countdownSeconds: 60,
        sendMsgBtnDisabled: false,
        sendMsgBtnText: this.$t("login.sendMSG"),
        sendMsgBtnLoading: false,
        elevatorId: 0,
        verifyCode: {
          phone: "",
          code: "",
        },
        ruleValidate: {
          code: {required: true, message: this.$t("login.emptyVer"), trigger: "blur"},
        },
      };
    },
    computed: {
      title() {
        return this.$t("login.getVer");
      },
    },
    methods: {
      open() {
        this.dialogVisible = true;
        this.getData();
      },
      getData() {
        this.$api.getData("account").then(res => {
          let data = res.data;
          this.verifyCode.phone = data.phone;
          this.contentLoading = false;
        }).catch((error) => {
          this.contentLoading = false;
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + error.response.data.message);
          }
        });
      },
      countdown() {
        if (this.countdownSeconds === 0) {
          this.sendMsgBtnDisabled = false;
          this.sendMsgBtnText = this.$t("login.sendMSG");
          this.countdownSeconds = 60;
        } else {
          this.sendMsgBtnDisabled = true;
          this.sendMsgBtnText = this.$t("login.resend") + this.countdownSeconds + "s";
          this.countdownSeconds--;
          setTimeout(() => {
            this.countdown();
          }, 1000);
        }
      },
      sendMsg() {
        this.$api.getData("remote/escalatorControl/sendMsg").then((res) => {
          this.countdown();
          this.$message.success(this.$t("login.verSuccess"));
          this.sendMsgBtnLoading = false;
        }).catch(error => {
          this.sendMsgBtnLoading = false;
          if (error.response) {
            this.$message.error(this.$t("login.verFail") + error.response.data.message);
          }
        });
      },
      handleSubmit() {
        this.ruleValidate.code.required = true;
        this.$refs.formValidate.validate((valid) => {
          if (valid) {
            let params = {
              phone: this.verifyCode.phone,
              code: this.verifyCode.code,
            };
            this.$api.getData("remote/escalatorControl/checkCode", params).then((res) => {
              this.$emit("select", this.verifyCode.code);
              this.dialogVisible = false;
            }).catch(error => {
              if (error.response.data.code === 1) {
                this.$message.error(this.$t("login.verNotRight"));
              }
            });
          }
        });
      },
      onDialogClose() {
        this.contentLoading = false;
        this.$refs.formValidate.resetFields();
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>
