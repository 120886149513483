<template>
  <el-dialog
    :title="$t('elevator.operationRecord')"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    width="800px"
    top="10vh"
    append-to-body
    class="small-padding"
    @close="onDialogClose">
    <div v-loading="contentLoading" class="body">
      <vm-table
        ref="vmTable"
        :filter.sync="search"
        url="remote">
        <el-table-column prop="data" :label="$t('elevator.order')"></el-table-column>
        <el-table-column prop="status" :label="$t('elevator.status')">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status === 1">{{$t("elevator.executing")}}</el-tag>
            <el-tag v-if="scope.row.status === 2" type="success">{{$t("elevator.success")}}</el-tag>
            <el-tag v-if="scope.row.status === 3" type="danger">{{$t("elevator.error")}}</el-tag>
            <el-tag v-if="scope.row.status === 4" type="warning">{{$t("elevator.overTime")}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="lastOperatingTime" :label="$t('elevator.time')"></el-table-column>
        <el-table-column prop="operaterAccount" :label="$t('elevator.operator')"></el-table-column>
      </vm-table>
    </div>
  </el-dialog>
</template>

<script>

  export default {
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        elevatorId: 0,
        search: {
          elevatorId: "",
        },
      };
    },
    methods: {
      open(elevatorId) {
        this.elevatorId = elevatorId;
        this.dialogVisible = true;
        this.$nextTick(() => {
          this.getList(1);
        });
      },
      getList(pageIndex) {
        this.search.elevatorId = this.elevatorId;
        this.$refs.vmTable.getList(pageIndex);
      },
      onDialogClose() {
        this.contentLoading = false;
        this.dialogVisible = false;
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>
